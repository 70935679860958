.pdf.new-page {
  width: 100%;
  max-width: 800px;
  background-color: white;
  padding: 24px;
}

.pdf-root {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.pdf.first-page {
  width: 100%;
  max-width: 800px;
  min-height: 900px;
  background-color: white;
  padding: 24px;
}

.break {
  background-color: #e8e8e8;
  max-width: 800px;
  height: 16px;
}
